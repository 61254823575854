import React from 'react';
import './whatOurClientsSay.scss';
import MaxSchoeppe from './Cards/MaxSchoeppe';
import Kaeding from './Cards/Kaeding';
import MichaelAngerer from "./Cards/MichaelAngerer";
import ThomasSchoeppe from "./Cards/ThomasSchoeppe";
import AndreSander from "./Cards/AndreSander";
import MartinGrundmann from "./Cards/MartinGrundmann";
import Adlassnig from "./Cards/Adlassnig";

import Slider from './Slider/Slider';

export default function WhatOurClientsSay() {

    const componentArray = [Adlassnig, MichaelAngerer, ThomasSchoeppe, AndreSander, Kaeding, MaxSchoeppe, MartinGrundmann];

    return (
        <main className="ContainerWhatOurClientsSay">
            <div className="HeadingWhatOurClientsSay">Das sagen unsere Kunden</div>
            <div>
                <Slider components={componentArray}/>
            </div>
        </main>
    );
}