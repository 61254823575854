import React from 'react';
import './Statement.scss';
// @ts-ignore
import image from '../../../static/assets/done.png';

interface Props {
    argument: string;
}

export default function Statement(props: Props) {

    return (
        <section className="ContentStatement">
            <div className="IconWrapperStatement">
                <img src={image} alt="Done"/>
            </div>
            <div className="ArgumentStatement">{props.argument}</div>
        </section>
    );
}