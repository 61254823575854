import React from 'react';
import {useMediaQuery} from 'react-responsive';

// @ts-ignore
import image from '../../../static/assets/hero-mdr.png';
// @ts-ignore
import imageProcessCard1 from "../../../static/assets/prozessanalyse.png"
// @ts-ignore
import imageProcessCard2 from "../../../static/assets/modellierung.png";
// @ts-ignore
import imageProcessCard3 from "../../../static/assets/zertifizierung.png";

import './threeStepsToAudit.scss';
import ProcessCard from "./ProcessCards/ProcessCard";

export default function ThreeStepsToAudit() {

    const isSmartphone = useMediaQuery({
        query: '(max-device-width: 500px)'
    });

    const headingProcessCard1 = "Prozessanalyse";
    const headingProcessCard2 = "Modellierung nach ISO 13485 und IEC 62304";
    const headingProcessCard3 = "Zertifizierung";

    const contentProcessCard1 = "Auf Basis der medizinischen Zweckbestimmung Ihres Produktes sowie weiterer Markt- und Produktanforderungen bestimmen wir mit erprobten Methoden und jahrelangem Fach-Know-how, \n" +
        "                wie ein zertifiziertes QM-System mit den vorhandenen Ressourcen umgesetzt werden kann. Gemeinsam gestalten wir Ihren Weg, \n" +
        "                um sowohl Bestandsprodukte als auch zukünftige Produkte nach Medizinprodukteverordnung - MDR - zuzulassen.";

    const contentProcessCard2 = "Zielgerichtet und pragmatisch bauen wir mit Ihnen die notwendigen Prozesse auf, um einer normkonformen QMS-Dokumentation nach ISO 13485 zu entsprechen. \n" +
        "                Ihren Entwicklungsprozess modellieren wir nach IEC 62304, zudem auch die Planung der klinischen Validierung und der klinischen Studie gehört.";
    const contentProcessCard3 = "Mit der Durchführung interner Audits und der Vorbereitung von Management Reviews bereiten wir Sie umfassend auf Ihr Audit vor. \n" +
        "                Wir prüfen zudem Ihre technische Dokumentation und begleiten Sie bei Ihrem Zertifizierungsaudit\n" +
        "                und darüber hinaus bei der von der MDR geforderten Post-Market Surveillance und beim Post-Market Clinical Follow-Up (PMCF).";

    const imageAltProcessCard1 = "Prozessanalyse";
    const imageAltProcessCard2 = "Modellierung nach ISO 13485 und IEC 62304";
    const imageAltProcessCard3 = "Zertifizierung";

    return (
        <main className="ContainerThreeStepsToAudit">
            <h1 className="HeadingThreeStepsToAudit">Digitale Gesundheits&shy;anwendungen auf den Weg bringen</h1>
            {!isSmartphone &&
            <h2 className="SubHeadingThreeStepsToAudit">
                Wir begleiten Sie in drei erprobten Schritten zum erfolgreichen Audit und darüber hinaus.
            </h2>
            }
            <article className="ProcessCardWrapper">
                <ProcessCard
                    heading={headingProcessCard1}
                    content={contentProcessCard1}
                    image={imageProcessCard1}
                    imageAlt={imageAltProcessCard1}/>
                <ProcessCard
                    heading={headingProcessCard2}
                    content={contentProcessCard2}
                    image={imageProcessCard2}
                    imageAlt={imageAltProcessCard2}/>
                <ProcessCard
                    heading={headingProcessCard3}
                    content={contentProcessCard3}
                    image={imageProcessCard3}
                    imageAlt={imageAltProcessCard3}/>
            </article>
        </main>
    );
}