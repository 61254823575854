import React from 'react';
import './ourServices.scss';
// @ts-ignore
import ServiceCard from "./ServiceCards/ServiceCard";
// @ts-ignore
import imageQualityManagement from "../../../static/assets/imagesSection3/icn-quality.png";
// @ts-ignore
import imageDocumentation from "../../../static/assets/imagesSection3/icn-documentation.png";
// @ts-ignore
import imageClinicalValuation from "../../../static/assets/imagesSection3/icn-evaluation.png";
// @ts-ignore
import imageCertification from "../../../static/assets/imagesSection3/icn-certificate.png";
// @ts-ignore
import imageRiskmanagement from "../../../static/assets/imagesSection3/icn-risk.png";
// @ts-ignore
import imageUsabilityEngineering from "../../../static/assets/imagesSection3/icn-testing.png";



export default function OurServices() {

    const headingQualityManagement = "Qualitätsmanagement nach ISO 13485";
    const textQualityManagement = `Wir sind Ihr Coach und zuverlässiger Begleiter auf dem Weg zum dokumentierten Qualitätsmanagement-System (QMS) nach ISO 13485.
                    Mit unseren erprobten Templates und Checklisten unterstützen wir Sie nach Ihren Bedürfnissen.`;
    
    const headingDocumentation = "Technische Dokumentation für Medizinprodukte";
    const textDocumentation = `Wir erstellen mit Ihnen die Technische Dokumentation von der Roadmap-Planung bis zur Konformitätsprüfung Ihrer Produkte.
                    Dabei unterstützen wir Sie mit allen benötigten Dokumenten zur Software-Architektur sowie zur Anforderungsspezifikation - alles aus einer Hand.`;
    
    const headingClinicalValuation = "Registerstudien und Post-Market-Surveillance";
    const textClinicalValuation = `Um den klinischen Nutzen Ihres Produktes vor und nach der Zulassung dokumentieren zu können, unterstützen wir Sie bei der Erstellung von 
                    klinischen Studien und Registern. Als erfahrener IT-Dienstleister wissen wir, wie wir komplexe Daten einfach präsentieren, um Ihre Fragen zu beantworten.`;
    
    const headingRiskmanagement = "Risikomanagement nach ISO 14971";                
    const textRiskmanagement = `Wir etablieren Ihren maßgeschneiderten Risikomanagement-Prozess nach ISO 14971.
                    Ob Risikoplanung, Risikobewertung oder Risikobewältigung - mit uns sind Sie auf der sicheren Seite!`;

    const headingUsabilityEngineering = "Usability Engineering nach IEC 62366";
    const textUsabilityEngineering = `Wir bieten Ihnen das Komplettprogramm aus User Research, Prototyping und UX / UI Design sowie Probanden-Akquise und Usability Testing 
                    (formative und summative Evaluation). Vertrauen Sie in punkto Gebrauchstauglichkeit auf unser erfahrenes Team aus Designern und UX-Spezialisten.`;

    const headingCertification = "Zertifizierung";
    const textCertification = `Mit der Durchführung interner Audits bereiten wir Sie sorgfältig auf das Zertifizierungsaudit vor, sodass Sie dieses ohne Scheu absolvieren werden.
                    Werden Sie eines von vielen Unternehmen, die wir schon erfolgreich zum zertifizierten Medizinprodukt begleitet haben!`;

    return (
        <main className="ContainerOurServices">
            <h1 className="HeadingOurServices">Unsere Services</h1>
            <div className="ServiceCards">
                <ServiceCard
                    text={textQualityManagement}
                    heading={headingQualityManagement}
                    image={imageQualityManagement}
                />
                <ServiceCard
                    text={textDocumentation}
                    heading={headingDocumentation}
                    image={imageDocumentation}
                />
                <ServiceCard
                    text={textClinicalValuation}
                    heading={headingClinicalValuation}
                    image={imageClinicalValuation}
                />
                <ServiceCard
                    text={textRiskmanagement}
                    heading={headingRiskmanagement}
                    image={imageRiskmanagement}
                />
                <ServiceCard
                    text={textUsabilityEngineering}
                    heading={headingUsabilityEngineering}
                    image={imageUsabilityEngineering}
                />
                <ServiceCard
                    text={textCertification}
                    heading={headingCertification}
                    image={imageCertification}
                />
            </div>
        </main>
    );
}