import React from 'react';
import './contactPerson.scss';
import ContactCard from './ContactCard';
// @ts-ignore
import imageJana from '../../../static/assets/Jana.png';
// @ts-ignore
import imageHans from '../../../static/assets/Hans.png';
import { Link } from 'gatsby';

export default function ContactPerson() {

    return (
        <main id="Kontakt" className="ContainerContactPerson">
            <h1 className="HeadingContactPerson">Ihre Ansprechpartner</h1>
            <section className="ContactPersonsSection">
                <ContactCard
                    company={"AKQUINET"}
                    email={"jana.metz@akquinet.de"}
                    image={imageJana}
                    name={"Jana Metz"}
                    quali={""}
                />
                <ContactCard
                    company={"HSE365AT"}
                    email={"johann.krocza@hse365.at"}
                    image={imageHans}
                    name={"Dr. Johann Krocza"}
                    quali={"Experte Medizinprodukteentwicklung und Qualitätsmanagement"}
                />
            </section>
            <div className="ContactFormContainer">
                <div className="ContactFormHeading">Oder schreiben Sie uns eine Nachricht über unser Kontaktformular</div>
                <Link className="ContactFormLink" to="/Kontakt">Kontaktformular</Link>
            </div>
        </main>
    );
}