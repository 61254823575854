import React from 'react';
// @ts-ignore
import michael from '../../../../static/assets/imagesTestimonials/Testimonial_Michael_Angerer.png'
import Card from './Card';

const MichaelAngerer = () => {

    const Name = "Michael Angerer";
    const Job = "Product Owner bei der engineeriQ GmbH im Auftrag der HSE365AT";
    const Text = `Die medizinprodukt-spezifische Dokumentation war für uns eine große Hürde, die wir gemeinsam mit Dr. Krocza erfolgreich nehmen konnten. 
    Mit seiner Expertise im Dokumentenmanagement waren wir in der Lage, die benötigten Dokumente fristgerecht und vollständig vorlegen zu können.`;

    return (
        <Card name={Name} job={Job} text={Text} image={michael}/>
    )
}

export default MichaelAngerer;
