import React from 'react';
// @ts-ignore
import './fitForAudit.scss';
import {navigate} from "gatsby";

export default function FitForAudit() {

    const navigateToQuiz = () => {
        navigate('/QuizPage');
    }

    return (
        <main className="FitForAuditContainer">
            <div className="QuizLeader">Sind Sie fit fürs Audit? Finden Sie es heraus!</div>
            <button className="ButtonQuizLeader" onClick={() => navigateToQuiz()}>Ihr Weg zum Medizinprodukt</button>
        </main>
    );
}