import React, {useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import './whatWeOffer.scss';
// @ts-ignore
import video from '../../../static/assets/16_Usability Engineering_Final_mitOutro.mp4';
import ReactPlayer from 'react-player';
// @ts-ignore
import thumbnail from '../../../static/assets/Thumbnail_Video.png';
import Statement from "./Statement";
import {statement1, statement2, statement3, statement4, statement5} from "./TextStatements";

export default function WhatWeOffer() {

    const smartPhoneVideo = ["100vw", "calc(100vw / 16 * 9)"];
    const desktopVideo = ["40vw", "calc(40vw / 16 * 9)"];
    const [videoWidth, setVideoWidth] = useState(desktopVideo[0]);
    const [videoHeight, setVideoHeight] = useState(desktopVideo[1]);

    const setDimensions = () => {
        if (window.innerWidth <= 1250){
            setVideoWidth(smartPhoneVideo[0]);
            setVideoHeight(smartPhoneVideo[1]);
        }else{
            setVideoWidth(desktopVideo[0]);
            setVideoHeight(desktopVideo[1]);
        }
    };

    React.useEffect(() => {
        setDimensions();
        window.addEventListener("resize", setDimensions, false);
    }, []);

    return (
        <div className="ContainerWhatWeOffer">
            <div className="ContentWhatWeOffer">
                <div className="VideoWrapperWhatWeOffer">
                    <ReactPlayer
                        className="VideoWhatWeOffer"
                        url={video}
                        light={thumbnail}
                        playing
                        controls
                        width={videoWidth}
                        height={videoHeight}
                    />
                </div>
                <div className="HeadingWhatWeOffer">Ihre Vorteile im Überblick</div>
                <div className="StatementListWrapper">
                    <Statement argument={statement1}/>
                    <Statement argument={statement2}/>
                    <Statement argument={statement3}/>
                    <Statement argument={statement4}/>
                    <Statement argument={statement5}/>
                </div>
            </div>
        </div>
    );
}