import React from 'react';
// @ts-ignore
import image from '../../../static/assets/hero-mdr.png';
import './blurb.scss';
import {navigate} from "gatsby";

export default function Blurb() {

    const navigateToQuiz = () => {
        navigate('/QuizPage');
    }

    return (
        <div className="ContainerBlurb">
            <div className="TextblockAndImage">
                <div className="Textblock">
                    <div className="Heading">Medizin&shy;produkte - <br/>Zulassung leicht gemacht</div>
                    <div className="Content">
                        Lösungsorientiert gehen wir mit Ihnen den Weg zur MDR- und IVDR-konformen Zulassung Ihrer 
                        Digitalen Gesundheits&shy;anwendung (DiGA), Ihres Medizinproduktes oder Ihrer IVD-Software. 
                        Unser erfahrenes, multi&shy;disziplinäres Team bietet Ihnen kompetenten Service nach Maß von A bis Z:
                        <br></br>
                        <br></br>
                        Ob Anforderungsanalyse, Qualitätsmanagement nach ISO 13485, 
                        Usability Engineering nach IEC 62366 oder zulassungsrelevante Reports - wir begleiten Sie 
                        zuverlässig und sicher auf jedem Schritt zur Zulassung Ihres Produktes und darüber hinaus.
                    </div>
                    <button className="ButtonQuiz" onClick={() => navigateToQuiz()}>Ihr Weg zum Medizinprodukt</button>
                </div>
                <div className="ImageWrapper">
                    <img className="Image" src={image} alt="Desk Art"/>
                </div>
            </div>
        </div>
    );
}