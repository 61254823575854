import React from 'react';
import './eHealthBlog.scss';
import Article from "./Article";

export default function EHealthBlog() {

    const subHeadingArticle1 = "Risikomanagement bei Medizinprodukten";
    const contentTextArticle1 = `Ein Medizinprodukt – ob ein Hardware-Gerät oder eine Software – soll einen bestimmten Nutzen erbringen. Bei der Anwendung können aber auch Risiken entstehen
                                Mit dem Risikomanagement bei Medizinprodukten werden die Risiken vor Inverkehrbringen des Produktes identifiziert, bewertet, 
                                soweit wie möglich eliminiert und weitere Maßnahmen zum Schutz der Anwender*innen ergriffen. `;
    const blogLinkArticle1 = "https://ehealthblog.akquinet.de/risikomanagement-bei-medizinprodukten-🩺/";

    const subHeadingArticle2 = "Kosteneinsparungen dank benutzerdefiniertem Design";
    const contentTextArticle2 = `Bereits 2005 veröffentlichte das Institute of Electrical and Electronic Engineers (IEEE)
                                eine Studie mit dem Titel „Why Software Fails“ („Warum Software scheitert“), die einige Fakten enthielt,
                                die schockierender Weise auch heute noch aktuell sind.`;
    const blogLinkArticle2 = "https://ehealthblog.akquinet.de/kosteneinsparungen-dank-benutzerzentriertem-design-ucd/";

    return (
        <main className="ContainerEHealthBlog">
            <h1 className="HeadingEHealthBlog">Auf dem eHealth Blog weiterlesen</h1>
            <section className="TextblocksRowEHealthBlog">
                <Article
                    Heading={subHeadingArticle1}
                    Text={contentTextArticle1}
                    Link={blogLinkArticle1}/>
                <Article
                    Heading={subHeadingArticle2}
                    Text={contentTextArticle2}
                    Link={blogLinkArticle2}/>
            </section>
        </main>
    );
}