import React from 'react';
// @ts-ignore
import grundmann from '../../../../static/assets/imagesTestimonials/Testimonial_Avatar.png'
import Card from './Card';

const MartinGrundmann = () => {

    const Name = "Martin Grundmann";
    const Job = "CTO Black Tusk GmbH";
    const Text = `Mit der Spezifikation der Zweckbestimmung und der Klassifizierung unserer Produkte haben wir Dank HSE365 von Anfang an den richtigen Weg eingeschlagen, 
    sodass wir unnötige Aufwände in der Produktentwicklung vermeiden konnten.`;

    return (
        <Card name={Name} job={Job} text={Text} image={grundmann}></Card>
    )
}

export default MartinGrundmann;

