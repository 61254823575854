import React from 'react';
// @ts-ignore
import thomas from '../../../../static/assets/imagesTestimonials/Testimonial_Thomas_Schoeppe.png'
import Card from './Card';

const ThomasSchoeppe = () => {

    const Name = "Thomas Schoeppe";
    const Job = "Geschäftsführer knowledgepark GmbH";
    const Text = `HSE365 hat uns maßgeblich bei der Definition unserer QM-Prozesse unterstützt und Projekte nach ISO13485 für uns abgewickelt.`;

    return (
        <Card name={Name} job={Job} text={Text} image={thomas}></Card>
    )
}

export default ThomasSchoeppe;