import React from 'react';
import './serviceCard.scss';

const ServiceCard = ({image, heading, text}) => {
    return (
        <div className="ServiceCard">
            <div className="ImageWrapperServiceCard">
                <img className="ImageServiceCard" src={image} alt={heading}/>
            </div>
            <div className="CardHeading">{heading}</div>
            <div className="CardText">{text}</div>
        </div>
    )
}

export default ServiceCard;