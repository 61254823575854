import React from 'react';
import Blurb from "../Blurb/Blurb";
import WhatWeOffer from '../WhatWeOffer/WhatWeOffer';
import OurServices from "../OurServices/OurServices";
import WhatOurClientsSay from "../WhatOurClientsSay/WhatOurClientsSay";
import EHealthBlog from "../EHealthBlog/EHealthBlog";
import ContactPerson from "../ContactPerson/ContactPerson";
import Footer from "../Footer/Footer";
import ThreeStepsToAudit from "../ThreeStepsToAudit/ThreeStepsToAudit";
import FitForAudit from "../FitForAudit/FitForAudit";
import Heading from '../Heading/Heading';
import { Helmet } from "react-helmet";

export default function Landingpage() {

    return (
        <div>
            <Helmet>
                <title>Digitale Medizinprodukte</title>
            </Helmet>
            <Heading/>
            <Blurb/>
            <ThreeStepsToAudit/>
            <WhatWeOffer/>
            <FitForAudit/>
            <OurServices/>
            <WhatOurClientsSay/>
            <EHealthBlog/>
            <ContactPerson/>
            <Footer/>
        </div>
    );
}