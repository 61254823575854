import React from 'react';
// @ts-ignore
import './contactPerson.scss';

type Props = {
    image: any;
    name: string;
    company: string;
    quali: string;
    email: string;
}

export default function ContactPerson(props: Props) {
    return (
        <main className="ContentContactPerson">
            <img className="ImagePerson" src={props.image} alt={props.name}/>
            <div className="NameContactPerson">{props.name}</div>
            <div className="Company">{props.company}</div>
            <div className="QualificationsContactPerson">{props.quali}</div>
            <div className="EmailContactPerson">
                <a className="EmailLink" href={"mailto:"+props.email}>{props.email}</a>
            </div>
        </main>
    )
}