import React from 'react';
// @ts-ignore
import './eHealthBlog.scss';

export default function Article({Heading, Text, Link}) {
    return (
        <main className="ContentArticle">
            <div className="VerticalLine"></div>
            <div className="SubHeadingArticle">{Heading}</div>
            <div className="ContentTextArticle">{Text}</div>
            <div className="BlogLinkSection7">
                <a className="BlogLink" href={Link}>Weiter lesen</a>
            </div>
        </main>
    )
}