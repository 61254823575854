import React from 'react';
// @ts-ignore
import '../whatOurClientsSay.scss';

type Props = {
    isActive: boolean;
}

const Circle = (props: Props) => {
    return (
        <>
            {props.isActive
                ?
                <div className="circleActive"/>
                :
                <div className="circleInactive"/>
            }
        </>
    );
}

export default Circle;