import React from 'react';
// @ts-ignore
import andre from '../../../../static/assets/imagesTestimonials/Testimonial_Andre_Sander.png'
import Card from './Card';

const AndreSander = () => {

    const Name = "Dr. André Sander";
    const Job = "CTO bei ID GmbH & Co. KGaA";
    const Text = `AKQUINET hat uns bei der Formativen Evaluation mit Eye-Tracking basierten Usability Tests unterstützt und wesentliche Verbesserungen für unsere Oberflächen identifiziert. 
    Effiziente Oberflächen sind essentiell für ein sicheres Medizinprodukt.`;

    return (
        <Card name={Name} job={Job} text={Text} image={andre}></Card>
    )
}

export default AndreSander;


