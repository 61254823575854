import React from 'react';
// @ts-ignore
import prof from '../../../../static/assets/imagesTestimonials/Testimonial_Prof.Adlassnig.png';
import Card from './Card';

const Adlassnig = () => {

    const Name = "Prof. Dr. Klaus Peter Adlassnig";
    const Job = "Medexter Healthcare GmbH";
    const Text = `Normgerechtes Qualitätsmanagement ist für unsere Entwicklungsprojekte essenziell. Dr. Krocza hat uns optimal beraten, 
    sodass wir mit vertretbarem Aufwand fit für die Medizinprodukte-Zulassung sind.`;

    return (
        <Card name={Name} job={Job} text={Text} image={prof}></Card>
    )
}

export default Adlassnig;