import React from 'react';
import './processCards.scss';

type Props = {
    heading: string;
    content: string;
    image: any;
    imageAlt: string;
}

export default function ProcessCard(props: Props) {

    return (
            <section className="ProcessCardWhiteBackground">
                <img className="ImagePC" src={props.image} alt={props.imageAlt}/>
                <h3 className="ProcessCardHeading">{props.heading}</h3>
                <div className="ProcessCardText">{props.content}</div>
            </section>
    )
}