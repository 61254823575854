import React from 'react';
// @ts-ignore
import kaeding from '../../../../static/assets/imagesTestimonials/Testimonial_Avatar.png'
import Card from './Card';

const Kaeding = () => {

    const Name = "Dr. André Kaeding";
    const Job = "Geschäftsführer Gesellschaft für medizinische Computersysteme GmbH";
    const Text = `Bei der Etablierung unseres Qualitäts-Management-Systems und der Durchführung unseres gemeinsamen EU-Projektes MISSION konnten wir voll und ganz auf Johann zählen. 
    Glücklicher Weise haben wir mit ihm einen Partner an der Seite, der uns vor unangenehmen Überraschungen bewahrt!`;

    return (
        <Card name={Name} job={Job} text={Text} image={kaeding}></Card>
    )
}

export default Kaeding;
